import * as React from "react";

import { faCar } from "@fortawesome/sharp-regular-svg-icons/faCar";
import { faCoffee } from "@fortawesome/sharp-regular-svg-icons/faCoffee";
import { faCoins } from "@fortawesome/sharp-regular-svg-icons/faCoins";
import { faHotel } from "@fortawesome/sharp-regular-svg-icons/faHotel";
import { faLamp } from "@fortawesome/sharp-regular-svg-icons/faLamp";
import { faLightbulb } from "@fortawesome/sharp-regular-svg-icons/faLightbulb";
import { faPear } from "@fortawesome/sharp-regular-svg-icons/faPear";
import { faPlane } from "@fortawesome/sharp-regular-svg-icons/faPlane";
import { faTicket } from "@fortawesome/sharp-regular-svg-icons/faTicket";

import { getFakeDateString } from "~utils/date_utils/getFakeDateString";
import { formatCurrencyString } from "~utils/i18n_utils/formatCurrencyString";

import { Box } from "~components/Box";
import { IconFontAwesome } from "~components/IconFontAwesome";
import { StoryblokEditable } from "~components/StoryblokEditable";

import { AnimationPaymentsListItemStatus } from "~animations/in_body_illustrations/AnimationPaymentsList/AnimationPaymentsListItemStatus";

import * as styles from "./styles.css";

import type { StatusOption } from "~animations/in_body_illustrations/AnimationPaymentsList/statuses";
import type { BoxProps } from "~components/Box";
import type { EnabledLanguageIsoCode } from "~config/ENABLED_LANGUAGES.config";
import type { StoryblokBlok } from "~types/storyblok.types";

export interface AnimLayerTransactionListItemProps
  extends StoryblokBlok,
    BoxProps {
  iconBackgroundColor?: "dark" | "light";
  isDateVisible?: boolean;
  isStatusVisible?: boolean;
  title?: string;
  value: number;
  icon: TransactionListItemEnum | undefined;
  status?: StatusOption;
  swatchColor?: string;
  amountsColor?: BoxProps["color"];
  index: number;
  lang?: EnabledLanguageIsoCode;
}

const TRANSACTION_LIST_ICON_MAP = {
  faCar,
  faCoffee,
  faCoins,
  faHotel,
  faLamp,
  faLightbulb,
  faPear,
  faPlane,
  faTicket,
} as const;

export type TransactionListItemEnum = keyof typeof TRANSACTION_LIST_ICON_MAP;

export function AnimLayerTransactionListItem({
  isDateVisible,
  isStatusVisible,
  title,
  lang = "en-gb",
  value,
  icon,
  status,
  swatchColor,
  index,
  amountsColor,
  ...rest
}: AnimLayerTransactionListItemProps) {
  const dynamicIcon = icon ? TRANSACTION_LIST_ICON_MAP[icon] : undefined;

  return (
    <StoryblokEditable key={rest._uid} {...rest}>
      <Box
        alignItems="center"
        className={styles.list}
        display="flex"
        gap="phoneGutteringSm"
        key={rest._uid}
        minHeight="spacing4"
        paddingY="phoneSpacing1"
      >
        {dynamicIcon && (
          <IconFontAwesome
            width="icon_md"
            height="icon_md"
            borderRadius="pill"
            padding="phoneSpacing1.5"
            border="default"
            className={styles.iconBG}
            icon={dynamicIcon}
          />
        )}
        {!icon && <Box className={styles.swatch} __color={swatchColor} />}
        <Box>
          <Box color="text_highContrast" textAppearance="phone_text_md">
            {title}
          </Box>
          {isDateVisible && (
            <Box color="text_lowContrast" textAppearance="phone_text_sm">
              {getFakeDateString(index, lang)}
            </Box>
          )}
        </Box>

        <Box
          marginLeft="auto"
          fontWeight="medium"
          color={
            amountsColor || (value > 0 ? "semantic_green" : "semantic_red")
          }
        >
          {formatCurrencyString({ amount: value, lang })}
        </Box>
        {isStatusVisible && (
          <Box className={styles.status}>
            {status && <AnimationPaymentsListItemStatus status={status} />}
          </Box>
        )}
      </Box>
    </StoryblokEditable>
  );
}
