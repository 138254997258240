import React from "react";

import { faCreditCard } from "@fortawesome/sharp-regular-svg-icons/faCreditCard";
import { faGear } from "@fortawesome/sharp-regular-svg-icons/faGear";
import { faHome } from "@fortawesome/sharp-regular-svg-icons/faHome";
import { faWallet } from "@fortawesome/sharp-regular-svg-icons/faWallet";
import clsx from "clsx";

import { variantBackgroundColor } from "~styles/common/variant.backgroundColor.css";

import { Box } from "~components/Box";
import { IconFontAwesome } from "~components/IconFontAwesome";

import * as styles from "./styles.css";

import type { IconDefinition } from "@fortawesome/sharp-regular-svg-icons";
import type { BoxProps } from "~components/Box";

interface AnimLayerUiBottomNavProps extends BoxProps {
  icon1?: IconDefinition;
  icon2?: IconDefinition;
  icon3?: IconDefinition;
  icon4?: IconDefinition;
}

export function AnimLayerUiBottomNav({
  className: userClassName,
  icon1 = faHome,
  icon2 = faCreditCard,
  icon3 = faWallet,
  icon4 = faGear,
  ...rest
}: AnimLayerUiBottomNavProps) {
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      className={clsx(
        styles.bottomNav,
        variantBackgroundColor.background_white,
        userClassName
      )}
      {...rest}
    >
      <IconFontAwesome icon={icon1} />
      <IconFontAwesome icon={icon2} />
      <IconFontAwesome icon={icon3} />
      <IconFontAwesome icon={icon4} />
    </Box>
  );
}
