import React from "react";

import { varsFakeBrand } from "~styles/themes/fakeBrands/theme_contract_fakeBrands.css";

import { ANIMATED } from "~utils/motion_one/motion_attribute_selectors";

import { Box } from "~components/Box";

import type { BoxProps } from "~components/Box";

interface AnimLayerUiCardTitleProps extends BoxProps {
  title?: string;
  ctaText?: string;
}

export function AnimLayerUiCardTitle({
  title,
  ctaText,
  marginBottom = "phoneSpacing2",
}: AnimLayerUiCardTitleProps) {
  if (!title && !ctaText) return null;

  return (
    <Box
      textAppearance="phone_text_sm"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      data-motion={ANIMATED.secondary}
      marginBottom={marginBottom}
      fontWeight="semibold"
    >
      {title && <Box color="text_highContrast">{title}</Box>}
      {ctaText && <Box __color={varsFakeBrand.color.primary}>{ctaText}</Box>}
    </Box>
  );
}
