import React from "react";

import { faLocationDot } from "@fortawesome/sharp-solid-svg-icons/faLocationDot";
import { faStar } from "@fortawesome/sharp-solid-svg-icons/faStar";

import { ANIMATED } from "~utils/motion_one/motion_attribute_selectors";

import { Box } from "~components/Box";
import { IconFontAwesome } from "~components/IconFontAwesome";
import { StoryblokEditable } from "~components/StoryblokEditable";

import * as styles from "./styles.css";

import type { IconProp } from "@fortawesome/fontawesome-svg-core";
import type { BoxProps } from "~components/Box";
import type { StoryblokBlok } from "~types/storyblok.types";

export interface AnimLayerTravelHotelInfoProps extends StoryblokBlok, BoxProps {
  title?: string;
  location?: string;
  textReviews?: string;
}

export function AnimLayerTravelHotelInfo({
  title,
  location,
  textReviews,
  ...rest
}: AnimLayerTravelHotelInfoProps) {
  return (
    <StoryblokEditable {...rest}>
      <Box {...rest}>
        <Box fontWeight="bold" data-motion={ANIMATED.secondary}>
          {title}
        </Box>
        <Box
          marginY="phoneSpacing0"
          display="flex"
          flexWrap="nowrap"
          alignItems="center"
          rowGap="phoneSpacing1"
          columnGap="phoneSpacing2"
          textAppearance="phone_text_sm"
          data-motion={ANIMATED.secondary}
        >
          <Box
            display="flex"
            gap="phoneSpacing1"
            alignItems="center"
            textAppearance="phone_text_sm"
          >
            <IconFontAwesome
              icon={faLocationDot as IconProp}
              className={styles.icon}
            />
            <Box color="text_lowContrast">{location}</Box>
          </Box>
          <Box display="flex" gap="phoneSpacing1" alignItems="center">
            <IconFontAwesome
              icon={faStar as IconProp}
              className={styles.icon}
            />
            <Box color="text_lowContrast">4.8</Box>
            <Box
              color="text_lowContrast"
              whiteSpace="nowrap"
              textDecoration="underline"
            >
              64 {textReviews}
            </Box>
          </Box>
        </Box>
      </Box>
    </StoryblokEditable>
  );
}
