import React, { useContext } from "react";

import { getFakeDateString } from "~utils/date_utils/getFakeDateString";
import { ANIMATED } from "~utils/motion_one/motion_attribute_selectors";

import { Box } from "~components/Box";
import { StoryblokEditable } from "~components/StoryblokEditable";

import { PageQueryContext } from "~context/PageQueryContext";

import type { BoxProps } from "~components/Box";
import type { StoryblokBlok } from "~types/storyblok.types";

export interface AnimLayerTravelBookingInfoProps
  extends StoryblokBlok,
    BoxProps {
  titleCheckIn: string;
  titleCheckOut: string;
  titleRoomsAndGuests: string;
  textRoomsAndGuests: string;
}

export function AnimLayerTravelBookingInfo({
  titleCheckOut,
  titleCheckIn,
  className: userClassName,
  titleRoomsAndGuests,
  textRoomsAndGuests,
  ...rest
}: AnimLayerTravelBookingInfoProps) {
  const { pageContext } = useContext(PageQueryContext) || {};
  const { lang } = pageContext || {};

  return (
    <StoryblokEditable {...rest}>
      <Box
        __gridTemplateColumns="repeat(2, 1fr)"
        borderRadius="md"
        data-motion={ANIMATED.secondary}
        display="grid"
        className={userClassName}
        gap="phoneSpacing2"
        paddingY="phoneSpacing2"
        paddingX="phoneSpacing2"
        textAppearance="body_sm"
        {...rest}
      >
        <Box>
          <Box fontWeight="bold">{titleCheckIn}</Box>
          <Box color="text_lowContrast">{getFakeDateString(-30, lang)}</Box>
        </Box>
        <Box>
          <Box fontWeight="bold">{titleCheckOut}</Box>
          <Box color="text_lowContrast">{getFakeDateString(-33, lang)}</Box>
        </Box>
        <Box>
          <Box fontWeight="bold">{titleRoomsAndGuests}</Box>
          <Box color="text_lowContrast">{textRoomsAndGuests}</Box>
        </Box>
      </Box>
    </StoryblokEditable>
  );
}
