import React from "react";

import { faBolt } from "@fortawesome/sharp-regular-svg-icons/faBolt";
import { faChevronDown } from "@fortawesome/sharp-regular-svg-icons/faChevronDown";

import { variantBackgroundColor } from "~styles/common/variant.backgroundColor.css";
import { varsFakeBrand } from "~styles/themes/fakeBrands/theme_contract_fakeBrands.css";

import { ANIMATED } from "~utils/motion_one/motion_attribute_selectors";

import { Box } from "~components/Box";
import { IconFontAwesome } from "~components/IconFontAwesome";
import { Image } from "~components/Image";
import { StoryblokEditable } from "~components/StoryblokEditable";

import * as styles from "./styles.css";

import type { BoxProps } from "~components/Box";
import type {
  StoryblokBlok,
  StoryblokFieldMedia,
} from "~types/storyblok.types";

export interface AnimLayerUiPayoutMethodProps extends StoryblokBlok, BoxProps {
  bankName?: string;
  ctaText?: string;
  description?: string;
  image?: StoryblokFieldMedia;
  textAccount?: string;
  title?: string;
}

export function AnimLayerUiPayoutMethod({
  bankName,
  ctaText: _ctaText, // TODO: Remove from CMS and interface
  description,
  image,
  textAccount: _textAccount, // TODO: Remove from CMS and interface
  title,
  ...rest
}: AnimLayerUiPayoutMethodProps) {
  return (
    <StoryblokEditable {...rest}>
      <Box
        borderRadius="sm"
        padding="phoneSpacing1.5"
        display="flex"
        flexDirection="column"
        className={variantBackgroundColor.background_white}
        border="default"
        {...rest}
      >
        <Box>
          <Box
            display="flex"
            alignItems="stretch"
            gap="phoneGutteringSm"
            data-motion={ANIMATED.secondary}
          >
            {image && (
              <Image
                width="image_xs"
                height="image_xs"
                image={image}
                borderRadius="sm"
              />
            )}
            <Box>
              {title && (
                <Box className={styles.tagStyle}>
                  <IconFontAwesome size="xs" icon={faBolt} />
                  {title}
                </Box>
              )}
              {bankName && (
                // {bankName && textAccount && (
                <Box textAppearance="phone_text_sm" fontWeight="semibold">
                  {bankName}
                  {/* {textAccount}  Remove from CMS */}
                  {" ******34"}
                </Box>
              )}
              {description && (
                <Box
                  data-motion={ANIMATED.secondary}
                  marginLeft="auto"
                  textAppearance="phone_text_xs"
                  color="text_lowContrast"
                >
                  {description}
                </Box>
              )}
            </Box>
            <Box
              alignItems="center"
              display="flex"
              __color={`${varsFakeBrand.color.primary}`}
            >
              <IconFontAwesome
                icon={faChevronDown}
                size="sm"
                padding="phoneSpacing0"
                width="icon_md"
                height="icon_md"
              />
            </Box>
            {/* <IconFontAwesome size="xs" icon={faBolt} /> */}
            {/* {title && (
              <Box textAppearance="phone_text_sm" fontWeight="semibold">
                {title}
              </Box>
            )}
            {ctaText && (
              <Box
                marginLeft="auto"
                textAppearance="phone_text_sm"
                __color={colorTokens_latest.Secondary.Water_azure[130].value}
              >
                {ctaText}
              </Box>
            )} */}
          </Box>
        </Box>

        {/* <Box
          textAppearance="phone_text_sm"
          display="flex"
          gap="phoneSpacing1"
          alignItems="center"
          data-motion={ANIMATED.secondary}
        >
          {image && (
            <Image
              maxWidth="phoneSpacing1"
              maxHeight="phoneSpacing2"
              image={image}
            />
          )}
          {bankName && textAccount && (
            <Box>
              {bankName} {textAccount}
              {" **** 5678"}
            </Box>
          )}
        </Box> */}
      </Box>
    </StoryblokEditable>
  );
}
