import React from "react";

import { faChevronRight } from "@fortawesome/sharp-regular-svg-icons/faChevronRight";

import { variantBackgroundColor } from "~styles/common/variant.backgroundColor.css";

import { ANIMATED } from "~utils/motion_one/motion_attribute_selectors";

import { Box } from "~components/Box";
import { IconFontAwesome } from "~components/IconFontAwesome";
import { Image } from "~components/Image";

import type { BoxProps } from "~components/Box";
import type { StoryblokFieldMedia } from "~types/storyblok.types";

export interface AnimLayerTrueLayerConnectionProps extends BoxProps {
  image?: StoryblokFieldMedia;
  bankName?: string;
  brandName?: string;
}

export function AnimLayerTrueLayerConnection({
  image,
  bankName = "Barclays", // TODO remove default value
  brandName = "JustShop", // TODO remove default value
  ...rest
}: AnimLayerTrueLayerConnectionProps) {
  return (
    <Box
      {...rest}
      alignItems="center"
      display="flex"
      gap="phoneSpacing1"
      marginY="phoneSpacing1"
      data-motion={ANIMATED.secondary}
      className={variantBackgroundColor.background_white}
      borderRadius="sm"
      paddingX="phoneSpacing2"
    >
      <Box>
        <Image image={image} loading="lazy" />
      </Box>

      <Box width="100%" marginX="phoneSpacing1.5">
        <Box
          paddingY="phoneSpacing2"
          borderBottom="extraLowContrast"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box color="text_highContrast" textAppearance="phone_text_sm">
            To
          </Box>
          <Box textAppearance="phone_text_md">{brandName}</Box>
        </Box>
        <Box
          paddingY="phoneSpacing2"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box color="text_lowContrast" textAppearance="phone_text_sm">
            From
          </Box>
          <Box
            textAppearance="phone_text_md"
            fontWeight="semibold"
            color="link_color"
            display="flex"
            alignItems="center"
            gap="phoneSpacingMin"
          >
            {bankName}
            <IconFontAwesome
              icon={faChevronRight}
              size="sm"
              width="phoneSpacing2"
              height="phoneSpacing2"
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
