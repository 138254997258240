import React from "react";

import clsx from "clsx";

import { variantBackgroundColor } from "~styles/common/variant.backgroundColor.css";

import { ANIMATED } from "~utils/motion_one/motion_attribute_selectors";

import { Box } from "~components/Box";
import { ImageBackground } from "~components/ImageBackground";
import { StoryblokEditable } from "~components/StoryblokEditable";
import { ShowChildren } from "~components/VisibilityCheck/ShowChildren";

import { ThemeContext } from "~sections/LayoutSection";

import { AnimLayerFakeButton } from "../AnimLayerFakeButton";
import * as styles from "./styles.css";

import type { AnimLayerFakeButtonProps } from "../AnimLayerFakeButton/types";
import type { BoxProps } from "~components/Box";
import type {
  StoryblokBlok,
  StoryblokFieldMedia,
} from "~types/storyblok.types";

export interface AnimLayerUiFirstDepositCardProps extends BoxProps {
  title?: string;
  button?: Array<StoryblokBlok & AnimLayerFakeButtonProps>;
  backgroundImage?: Array<StoryblokBlok & StoryblokFieldMedia>;
}

export function AnimLayerUiFirstDepositCard({
  button: buttonBlokArray,
  className: userClassName,
  title,
  backgroundImage: backgroundImageArray,
  ...rest
}: AnimLayerUiFirstDepositCardProps) {
  const [button] = buttonBlokArray || [];
  const [backgroundImage] = backgroundImageArray || [];

  return (
    <ThemeContext.Provider value="background_charcoal">
      <StoryblokEditable {...rest}>
        <Box
          position="relative"
          borderRadius="md"
          className={clsx(
            userClassName,
            variantBackgroundColor.background_charcoal
          )}
          border="default"
          display="flex"
          flexDirection="column"
          gap="phoneSpacing3"
          paddingX="phoneSpacing2"
          paddingY="phoneSpacing3"
          overflow="hidden"
          {...rest}
        >
          <ShowChildren when={backgroundImage}>
            <ImageBackground
              className={styles.bgImage}
              image={backgroundImage!}
            />
          </ShowChildren>
          <Box>
            <Box
              textAppearance="phone_h6"
              textAlign="center"
              paddingX="phoneSpacing1"
              data-motion={ANIMATED.secondary}
            >
              {title}
            </Box>
          </Box>
          <ShowChildren when={button}>
            <Box gap="phoneSpacing1" data-motion={ANIMATED.secondary}>
              <AnimLayerFakeButton
                color="text_highContrast"
                justifyContent="space-between"
                size="sm"
                variant="secondary"
                {...button}
              />
            </Box>
          </ShowChildren>
        </Box>
      </StoryblokEditable>
    </ThemeContext.Provider>
  );
}
