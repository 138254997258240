import React, { useContext, useMemo } from "react";

import { getSprinkles } from "~styles/getSprinkles.css";

import { formatCurrencyString } from "~utils/i18n_utils/formatCurrencyString";

import { Box } from "~components/Box";
import { StoryblokEditable } from "~components/StoryblokEditable";

import { SvgAnimProgressSemicircle } from "~animations/__layers__/animated_icons/SvgAnimProgressSemicircle";

import { PageQueryContext } from "~context/PageQueryContext";

import type { BoxProps } from "~components/Box";
import type { StoryblokBlok } from "~types/storyblok.types";

export interface AnimLayerUiBalanceWithGraphProps
  extends StoryblokBlok,
    BoxProps {
  amount?: number;
  limit?: number;
  textAvailableToUse?: string;
  textCreditLimit?: string;
}

export function AnimLayerUiBalanceWithGraph({
  amount,
  limit,
  textAvailableToUse,
  textCreditLimit,
  ...rest
}: AnimLayerUiBalanceWithGraphProps) {
  const { pageContext } = useContext(PageQueryContext) || {};
  const { lang } = pageContext || {};
  const minimumFractionDigits = 0;
  const remaining = useMemo(() => {
    if (typeof amount === "undefined" || typeof limit === "undefined") {
      return 0;
    }

    return limit - amount;
  }, [amount, limit]);

  return (
    <StoryblokEditable {...rest}>
      <Box
        {...rest}
        position="relative"
        display="flex"
        flexDirection="column"
        justifyContent="end"
        alignItems="center"
        aspectRatio="wide"
      >
        <SvgAnimProgressSemicircle
          className={getSprinkles({
            maxWidth: "100%",
            position: "absolute",
            inset: "0",
            paddingX: "phoneSpacing2",
          })}
        />

        {amount && (
          <Box marginX="auto" textAppearance="h2">
            {formatCurrencyString({
              amount,
              lang,
              minimumFractionDigits,
            })}
          </Box>
        )}

        <Box textAppearance="phone_text_md" color="text_lowContrast">
          <Box as="span" fontWeight="semibold">
            {formatCurrencyString({
              amount: remaining,
              lang,
              minimumFractionDigits,
            })}{" "}
          </Box>

          {textAvailableToUse}
        </Box>

        {limit && textCreditLimit && (
          <Box textAppearance="phone_text_md" color="text_lowContrast">
            {formatCurrencyString({
              amount: limit,
              lang,
              minimumFractionDigits,
            })}{" "}
            {textCreditLimit}
          </Box>
        )}
      </Box>
    </StoryblokEditable>
  );
}
